import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  getAllWeddingWebsiteCustomPageNames,
  getPublishedWeddingWebsite,
} from "../../models/weddingWebsiteTemplates/weddingWebsiteTemplates";
import { setCurrentTemplate } from "../../store/weddingWebsite/weddingWebsite";

const Config = {
  // "rose-bud": {
  //   "home": () => import("../wedding-website/wedding-templates/rose-bud/home"),
  //   "our-story": () =>
  //     import("../wedding-website/wedding-templates/rose-bud/our-story"),
  //   "photos": () =>
  //     import("../wedding-website/wedding-templates/rose-bud/photos"),
  //   "qa": () => import("../wedding-website/wedding-templates/rose-bud/qa"),
  //   "registry": () =>
  //     import("../wedding-website/wedding-templates/rose-bud/registry"),
  //   "rsvp": () => import("../wedding-website/wedding-templates/rose-bud/rsvp"),
  //   "things-to-do": () =>
  //     import("../wedding-website/wedding-templates/rose-bud/things-to-do"),
  //   "travel": () =>
  //     import("../wedding-website/wedding-templates/rose-bud/travel"),
  //   "wedding-party": () =>
  //     import("../wedding-website/wedding-templates/rose-bud/wedding-party"),
  // },
  "arya": {
    home: () => import("../wedding-website/wedding-templates/arya/home"),
  },
  "saira": {
    home: () => import("../wedding-website/wedding-templates/saira/home"),
  },
  "kalyani": {
    home: () =>
      import("../wedding-website/wedding-templates/kalyani/home"),
  },
  "sandhya": {
    home: () =>
      import("../wedding-website/wedding-templates/sandhya/home"),
  },
  "jiya": {
    home: () => import("../wedding-website/wedding-templates/jiya/home"),
  },
  "esha": {
    home: () => import("../wedding-website/wedding-templates/esha/home"),
  },
  // "toledo": {
  //   "home": () => import("../wedding-website/wedding-templates/toledo/home"),
  //   "our-story": () =>
  //     import("../wedding-website/wedding-templates/toledo/our-story"),
  //   "photos": () =>
  //     import("../wedding-website/wedding-templates/toledo/photos"),
  //   "qa": () => import("../wedding-website/wedding-templates/toledo/qa"),
  //   "registry": () =>
  //     import("../wedding-website/wedding-templates/toledo/registry"),
  //   "rsvp": () => import("../wedding-website/wedding-templates/toledo/rsvp"),
  //   "things-to-do": () =>
  //     import("../wedding-website/wedding-templates/toledo/things-to-do"),
  //   "travel": () =>
  //     import("../wedding-website/wedding-templates/toledo/travel"),
  //   "wedding-party": () =>
  //     import("../wedding-website/wedding-templates/toledo/wedding-party"),
  // },
};

function Page({
  color,
  customPageName,
  home,
  events,
  pages,
  sections,
  sectionState,
  templateId,
  requirePassword,
  domain,
  registries,
  isSinglePageTemplate,
  sectionSeperator,
  sitePassword
}) {
  const router = useRouter();
  const { pageId } = router.query;
  const dispatch = useDispatch();

  useEffect(() => {
    // all the keys below should be added to models/weddingWebsiteTemplates/weddingWebsiteTemplates.js >>> getPublishedWeddingWebsite
    const storeData = {
      color,
      customPageName,
      home,
      events,
      pages,
      sections,
      sectionState,
      templateId,
      requirePassword,
      domain,
      registries,
      isSinglePageTemplate,
      sectionSeperator,
      sitePassword
    };
    dispatch(setCurrentTemplate(storeData));
  }, [
    color,
    customPageName,
    home,
    events,
    pages,
    sections,
    sectionState,
    templateId,
    requirePassword,
    domain,
    registries,
    isSinglePageTemplate,
    sectionSeperator,
    sitePassword,
    dispatch,
  ]);

  const DynamicPage = dynamic(() => Config[templateId][pageId](), {
    loading: Loading,
    ssr: false,
  });

  return <DynamicPage />;
}

export async function getStaticPaths() {
  const paths = await getAllWeddingWebsiteCustomPageNames();
  return {
    paths,
    fallback: false,
  };
}

export async function getStaticProps({ params }) {
  const { weddingWebsiteCustomPageName } = params;
  const data = await getPublishedWeddingWebsite(weddingWebsiteCustomPageName);

  if (!data) {
    return {
      notFound: true,
    };
  }

  const {
    color,
    customPageName,
    home,
    events,
    pages,
    sections,
    sectionState,
    templateId,
    sitePassword,
    domain,
    registries,
    isSinglePageTemplate,
    sectionSeperator,
  } = data;

  const requirePassword = Boolean(data?.requirePassword && sitePassword);

  return {
    props: {
      color,
      customPageName,
      home,
      events,
      pages,
      sections,
      sectionState,
      templateId,
      requirePassword,
      domain,
      registries,
      isSinglePageTemplate,
      sectionSeperator,
      sitePassword
    },
  };
}

function Loading() {
  return <p>Loading...</p>;
}

Page.propTypes = {
  color: PropTypes.string.isRequired,
  customPageName: PropTypes.string.isRequired,
  home: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  sections: PropTypes.object.isRequired,
  sectionState: PropTypes.object.isRequired,
  templateId: PropTypes.string.isRequired,
  requirePassword: PropTypes.bool.isRequired,
  domain: PropTypes.object,
  registries: PropTypes.array.isRequired,
  isSinglePageTemplate: PropTypes.bool.isRequired,
  sectionSeperator: PropTypes.object.isRequired,
  sitePassword: PropTypes.string,
};

export default Page;
